<template>
  <footer>
      <div class="mx-auto w-full max-w-screen-xl px-6 pb-14 pt-28">
          <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0">
                <router-link class="flex items-center" to="/">
                    <img src="@/assets/images/CW-Blanc.svg" class="h-8 me-3" alt="Charly Web Wave" />
                    <span class="self-center text-2xl font-semibold whitespace-nowrap  text-white">Web Wave</span>
                </router-link>
            </div>
            <div class="grid gap-8 sm:gap-6">
                <div>
                    <h2 class="mb-6 text-sm font-semibold uppercase text-white">Navigation</h2>
                    <ul class="text-gray-300 font-medium">
                        <li class="mb-4">
                            <router-link class="font-bold hover:underline" to="/about">About</router-link>
                        </li>
                        <li class="mb-4">
                            <router-link class="font-bold hover:underline" to="/contact">Contact</router-link>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/charles-buquet-893021149/" class="font-bold hover:underline" target="_blank">Mon linkedin</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr class="my-6 sm:mx-auto border-gray-700 lg:my-8" />
        <div class="sm:flex sm:items-center sm:justify-between">
            <span class="text-sm sm:text-center">© 2024 <a href="/" class="hover:underline">WebWave™</a>. All Rights Reserved.
            </span>
            <div class="flex mt-4 sm:justify-center sm:mt-0">
                <a href="https://www.linkedin.com/in/charles-buquet-893021149/" target="_blank" title="Linkedin" class="hover:text-gray-300 s-5">
                    <font-awesome-icon :icon="['fab', 'linkedin']" />
                    <span class="sr-only">Linkedin</span>
                </a>
            </div>
        </div>
      </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  props: {
    msg: String
  }
}
</script>
