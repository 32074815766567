<template>
  <router-view/>
</template>

<script>
import { onMounted } from 'vue';
import { initFlowbite } from 'flowbite';

export default {
  name: 'App',
  setup() {
    // initialize components based on data attribute selectors
    onMounted(() => {
      initFlowbite();
    });

    // Vos autres configurations de composant ici

    return {};
  },
};
</script>
