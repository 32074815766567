<template>
    <section name="TextImg" class="bg-gray-950">
      <div class="gap-8 items-center py-16 px-5 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-32">
          <div class="mt-4 md:mt-0">
              <h2 class="mb-4">Expert en Développement Web et Applications Mobiles</h2>
              <p class="mb-6 font-light md:text-lg text-gray-300">Boostez votre présence en ligne avec Web Wave, votre spécialiste freelance en développement web et création d'applications mobiles. De la conception de sites web innovants à la réalisation d'applications mobiles sur mesure, je transforme vos idées en solutions numériques performantes. Profitez d'une approche personnalisée pour atteindre vos objectifs digitaux et captiver votre public cible.</p>
              <a href="mailto:charles@charlywebwave.fr" title="Mail">
                <button class="primary-sm">Contactez-moi</button>
              </a>
          </div>
          <img class="w-full rounded-lg mt-6 sm:mt-0" src="@/assets/images/brainstorming.webp" alt="dashboard image">
      </div>
    </section>
</template>

<script>
export default {
    name: 'TextImg',
}
</script>
