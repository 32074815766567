<template>
    <section id="projects" name="my-projects" class="fl-projects bg-purple-dark py-16 sm:py-32">
        <div class="container mx-auto relative">
            <div class="absolute top-1/2 -translate-y-1/2 left-0 w-0.5 h-2/3" style="background: linear-gradient(-45deg, transparent, #d01a78, #0589b9, #23d5ab, transparent);"></div>

            <div class="flex mb-4">
              <div class="box">
                <h2 class="z-10">Mes projets</h2>
              </div>
            </div>
            <div class="container-cards">
                <div class="box">
                    <span></span>
                    <div class="content">
                        <h2>Corbeau Blanc</h2>
                        <p>Lead Développeur chez Corbeau Blanc depuis 3 ans, j'ai collaboré sur des projets complets, de la conception à la réalisation, avec des designers et clients. Mon expertise technique a permis de créer des solutions efficaces et innovantes.</p>
                            <a href="https://corbeau-blanc.com/" title="VIsiter le site" target="_blank">
                <button class="primary-sm">Visiter le site</button>
              </a>
                    </div>
                </div>
                <div class="box">
                    <span></span>
                    <div class="content">
                        <h2>Casden</h2>
                        <p>J'ai contribué à la création du site de Casden Banque Populaire, permettant une gestion de contenu facile, et au développement du front-end de leur application mobile. J'ai aussi assuré une documentation et des formations.</p>
                            <a href="https://www.casden.fr/" title="VIsiter le site" target="_blank">
                <button class="primary-sm">Visiter le site</button>
              </a>
                    </div>
                </div>
                <div class="box">
                    <span></span>
                    <div class="content">
                        <h2>Neoen</h2>
                        <p>J'ai créé un site WordPress pour Neoen, duplicable et traduit en plusieurs langues (finnois, allemand, suédois, etc.). Je gère aussi la maintenance des serveurs et des sites, répondant aux besoins de l'entreprise dans divers pays.</p>
                        <a href="https://neoen.com/en/" title="VIsiter le site" target="_blank">
                            <button class="primary-sm">Visiter le site</button>
                        </a>
                    </div>
                </div>
                <div class="box">
                    <span></span>
                    <div class="content">
                        <h2>Teritoria</h2>
                        <p>Lors de mon alternance, j'ai contribué à diverses intégrations sur le site de l'entreprise du chef étoilé Alain DUCASSE. J'ai créé plus de 80 templates de réservation en ligne pour les hôtels et intégré un système de paiement via une cagnotte Leetchi.</p>
                        <a href="https://www.teritoria.com/" title="VIsiter le site" target="_blank">
                            <button class="primary-sm">Visiter le site</button>
                        </a>
                    </div>
                </div>
                <div class="box">
                    <span></span>
                    <div class="content">
                        <h2>Vyv3 Ile-de-France</h2>
                        <p>J'ai collaboré avec l'équipe IT et Communication de VYV3 Île-de-France pour concevoir et entretenir leur site web, assurant ainsi une expérience utilisateur optimale. Une stratégie SEO réussie a amplifié leur visibilité en ligne.</p>
                        <a href="https://idf.vyv3.fr/" title="VIsiter le site" target="_blank">
                            <button class="primary-sm">Visiter le site</button>
                        </a>
                    </div>
                </div>
                <div class="box">
                    <span></span>
                    <div class="content">
                        <h2>Remarquable</h2>
                        <p>En freelance pour Remarquable, j'ai créé avec une designer un site web réactif et convivial. Notre expertise a boosté l'expérience utilisateur et renforcé leur présence en ligne. Fier d'avoir contribué à un site performant pour leurs clients.</p>
                        <a href="https://remarquablehotels.com/" title="VIsiter le site" target="_blank">
                            <button class="primary-sm">Visiter le site</button>
                        </a>
                    </div>
                </div>
                <div class="box">
                    <span></span>
                    <div class="content">
                        <h2>PPC Conseil patrimoine</h2>
                        <p>Chez Corbeau Blanc, j'ai développé le site web de PPC Conseil en Patrimoine avec expertise, améliorant ainsi leur visibilité et l'expérience utilisateur. Fier d'avoir contribué à un site performant et accessible pour leurs clients.</p>
                        <a href="https://www.pccsarl.com/" title="VIsiter le site" target="_blank">
                            <button class="primary-sm">Visiter le site</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'MyProjects',
}
</script>
