<template lang="">
    <section class="min-h-screen relative">
        <img class="object-cover object-top w-full h-full absolute" src="@/assets/images/bg-main-hero.webp" alt="Main hero image">
        <div class="min-h-screen flex items-center relative">
          <div class="container mx-auto relative z-10">
            <div class="flex flex-col gap-2 w-full md:w-8/12 2xl:w-1/2 pt-28 md:pt-0">
              <div class="surtitle">Trouvez un</div>


              <div class="freelancer md:w-[800px]">
                <swiper
                :slides-per-view="1"
                :space-between="10"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                :loop="true"
                :modules="[Autoplay, EffectFade]" effect="fade"
                :autoplay="{ delay: 2500, disableOnInteraction: false }"
                >
                  <swiper-slide><h1>Developer</h1></swiper-slide>
                  <swiper-slide><h1>Full Stack</h1></swiper-slide>
                  <swiper-slide><h1>Wordpress</h1></swiper-slide>
                  <swiper-slide><h1>VueJs</h1></swiper-slide>
                </swiper>
              </div>

              <p class="md:text-xl font-bold pb-3" meta-description>Développez votre potentiel digital. Création sur-mesure de sites web, applications mobiles, templates personnalisés et campagnes d'emailing. Transformez votre vision en réalité.</p>
              <div class="w-max">
                <button @click="downloadPdf('/CV-Charles-2024.pdf')" class="primary">Télécharger mon CV</button>
              </div>
            </div>
          </div>
        </div>
        <div class="relative md:absolute bottom-0 right-0 flex justify-end">
          <div class="relative border border-transparent z-10 rounded-tl-3xl text-left p-12 overflow-hidden w-[275px] md:w-[400px]">
            <div class="bg-gray-900 bg-opacity-50 top-0 left-0 absolute blur h-full w-full"></div>
            <div class="relative z-10">
              <div class="font-bold text-base md:text-lg pb-1">Besoin de mes services ?</div>
              <div class="font-medium text-sm md:text-base pb-4">Envie de créer un site web ou une application mobile?</div>
              <a href="mailto:charles@charlywebwave.fr" title="Mail">
                <button class="primary-sm">Contactez-moi</button>
              </a>
            </div>
        </div>
        </div>
    </section>
</template>
<style>
.swiper-slide {
  opacity: 0 !important;
  transition: opacity 0.6s ease;
}

.swiper-slide-visible {
  opacity: 1 !important;
}
</style>
<script>
  import { Autoplay, EffectFade } from 'swiper/modules';
  import { Swiper, SwiperSlide } from 'swiper/vue';

  import 'swiper/css/bundle';
  import useDownloadPdf from './composables/UseDlPdf';

  export default {
    name: 'MainHero',
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
        onSwiper: Function,
        onSlideChange: Function,
    },
    setup() {
    const { downloadPdf } = useDownloadPdf();


      return {
        EffectFade, Autoplay, downloadPdf,
      };
    },
  };
</script>
