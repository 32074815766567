<template>
    <div class="">
      
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: 'AboutPage',
    props: {
      msg: String
    },
  }
  </script>