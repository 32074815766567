import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import ContactForm from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: About
  },
  {
    path: '/contact',
    name: 'ContactForm',
    component: ContactForm
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to) {
    let position = {}
    if (to.hash) {
      position = {
        el: to.hash
      };
    } else {
      position = {left: 0, top: 0}
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(position)
      }, 10)
    })
  }
})


export default router
