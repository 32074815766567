<template>
    <section name="ImgText" class="bg-gray-950">
      <div class="gap-8 items-center py-16 px-5 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-32">
          <img class="w-full rounded-lg" src="@/assets/images/computer-1.webp" alt="dashboard image">
          <div class="mt-4 md:mt-0">
              <h2 class="mb-4">Aller droit au but</h2>
              <p class="mb-6 font-light md:text-lg text-gray-300">Découvrez les solutions web, conçues pour dynamiser votre présence en ligne. Grâce à une expertise approfondie en développement de sites web, applications mobiles et outils de communication digitale, chaque projet est une porte ouverte vers de nouvelles opportunités. Explorez des créations uniques et engagez votre audience de manière efficace.</p>
              <router-link :to="{ path: '/', hash: '#projects' }" title="Projects">
                <button class="primary-sm">Voir mes projets</button>
              </router-link>
          </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'ImgText',
}
</script>
