<template>
  <MainHeader />

  <MainHero />
  <PresentationBlock /> 
  <ImgText />
  <MainSkills />
  <TextImg />
  <MyProjects />

  <MainFooter />
</template>

<script>
import MainHeader from '../components/layouts/MainHeader.vue'
import MainFooter from '../components/layouts/MainFooter.vue'
import MainHero from '../components/sections/MainHero.vue'
import PresentationBlock from '../components/sections/PresentationBlock.vue'
import MainSkills from '../components/sections/MainSkills.vue'
import TextImg from '../components/sections/TextImg.vue'
import ImgText from '../components/sections/ImgText.vue'
import MyProjects from '@/components/sections/MyProjects.vue'

export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  components: {
    MainHeader, MainFooter, MainHero, PresentationBlock, MainSkills, TextImg, ImgText,
    MyProjects,
  },
  metaInfo() {
    return {
      title: 'Charly Web Wave',
      meta: [
        { 
          name: 'description',
          content: 'Développez votre potentiel digital. Création sur-mesure de sites web, applications mobiles, templates personnalisés et campagnes d\'emailing. Transformez votre vision en réalité.'
        }
      ]
    }
  }
}
</script>

<style>
.freelancer {
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 110%);
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 110%);
}
</style>