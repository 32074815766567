<template>
    <section class="bg-purple-dark">
      <div class="py-16 sm:py-32">
        <div class="container mx-auto">
          <div class="flex flex-col-reverse md:flex-row gap-8">
            <div class="w-full md:w-1/3 flex flex-col justify-center gap-4">
              <div class="text-9xl font-bold text-center">3+</div>
              <div class="text-2xl font-bold w-48 mx-auto">Années d'experiences</div>
            </div>
            <div class="w-full md:w-3/4">
              <div class="flex flex-col items-center pb-8">
                <div class="box">
                  <h2 class="z-10">About me</h2>
                </div>
              </div>
              <div>
                Je m'appelle Charles, un développeur web full stack diplômé en 2021. Depuis, j'explore le développement web, tirant parti de mon expérience en alternance, notamment chez "les collectioneurs". Habitué à travailler avec des équipes variées comme les designers et les marketeurs, je crée des solutions web efficaces et user-friendly.
              </div>
              <a class="" href="https://www.linkedin.com/in/charles-buquet-893021149/" target="_blank">
                <button class="primary-sm mt-6">Mon Linkedin</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
  name: 'PresentationBlock',
}
</script>