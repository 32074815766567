import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Importer FontAwesome et le composant Vue
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Importer toutes les icônes de marque
import * as brands from '@fortawesome/free-brands-svg-icons';
import { faGem, faDatabase } from '@fortawesome/free-solid-svg-icons';

import { createMetaManager } from 'vue-meta';

// Ajouter toutes les icônes de marque à la bibliothèque
for (const icon in brands) {
  if (brands[icon].iconName) {
    library.add(brands[icon]);
  }
}

library.add(faGem, faDatabase);

// Ajouter le CSS global
import './index.css';

// Créer l'application Vue et utiliser les icônes FontAwesome
  createApp(App).use(router).use(createMetaManager).component('font-awesome-icon', FontAwesomeIcon).mount('#app');