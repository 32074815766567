<template>
    <section name="main-skills" class="bg-gray-950 py-16 relative">

    <div class="absolute -top-96 right-0 w-[650px] h-[650px] pointer-events-none">
        <img class="object-contain" src="@/assets/images/planets.png" alt="Splash">
    </div>
    <div class="absolute bottom-32 sm:-bottom-56 -left-44 w-[650px] pointer-events-none">
        <img class="object-contain" src="@/assets/images/planets.png" alt="Splash" style="transform: scaleX(-1)">
    </div>
    <div class="absolute top-1/2 left-1/2 -translate-y-1/2 w-[450px] h-[450px]" style="background: rgba(88, 101, 242, 0.5);filter: blur(250px);"></div>
    <div class="absolute top-1/2 left-1/2 -translate-y-1/2 w-[200px] h-[200px]" style="background: rgba(0, 249, 214, 0.5);filter: blur(250px);"></div>

      <div class="container mx-auto">
        <div class="flex mb-4">
          <div class="box">
            <h2 class="z-10">Main Skills</h2>
          </div>
        </div>
        <div class="flex flex-row justify-center md:justify-between flex-wrap">
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'vuejs']" />
                <h3>Vue</h3>
              </div>
            </div>
            <div class="face face2">
                <div class="content">
                    <p>Vue.js est un framework JavaScript efficace pour créer des UI dynamiques. Léger et adaptable, il est parfait pour des applications web interactives.</p>
                    <div class="flex gap-2 items-center mt-6">
                        <span class="text-gray-700 text-sm">Niv</span>
                        <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                            <div class="w-[70%] h-full rounded-full"></div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'react']" />
                <h3>ReactJs</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>React est une bibliothèque JavaScript de Facebook pour construire des UIs dynamiques avec des composants réutilisables, idéale pour les apps web et mobiles.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[70%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'php']" />
                <h3>PHP</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>PHP est un langage de script côté serveur utilisé pour développer des applications web dynamiques. Il est simple et efficace pour la gestion de données.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[90%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fas', 'gem']" />
                <h3>Ruby</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>Ruby est un langage de programmation orienté objet, facile à lire et à écrire. Il est utilisé pour développer des applications web rapidement via Rails.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[50%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'laravel']" />
                <h3>Laravel</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>Laravel est un framework PHP pour développer des applications web. Il offre une écriture de code élégante, sécurisée et des fonctionnalités robustes.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[80%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'node']" />
                <h3>Node</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>Node.js permet de construire des applications web rapides et évolutives côté serveur, utilisant JavaScript. Idéal pour des solutions en temps réel.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[60%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'wordpress']" />
                <h3>Wordpress</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>WordPress est un système de gestion de contenu flexible pour créer des sites web personnalisables grâce à une large sélection de thèmes et plugins.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[100%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'docker']" />
                <h3>Docker</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p> Docker facilite le déploiement d'applications dans des conteneurs, garantissant la portabilité et la cohérence entre les environnements de développement.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[50%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'php']" />
                <h3>Php unit</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>PHPUnit est un framework de test pour PHP, indispensable pour le développement piloté par les tests, assurant que le code fonctionne correctement.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[50%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'rebel']" />
                <h3>Cypress</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>Cypress est un outil de test automatisé pour des applications web, permettant aux développeurs de créer des tests de manière rapide et fiable.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[50%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'github']" />
                <h3>Github</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>GitHub est une plateforme de gestion de développement qui facilite la collaboration, le contrôle de version et le suivi de problèmes pour les équipes.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[100%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'gitlab']" />
                <h3>Gitlab</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>GitLab est une plateforme tout-en-un pour le contrôle de version sous Git et CI/CD, améliorant la collaboration et l'efficacité des développeurs.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[100%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fas', 'database']" />
                <h3>MYSQL</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>MySQL est un système de gestion de bases de données relationnelles, optimisé pour les applications web nécessitant un traitement efficace des données.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[100%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'stripe']" />
                <h3>Stripe</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>Stripe est une plateforme de paiement en ligne qui simplifie les transactions financières pour les entreprises, avec des API flexibles pour les intégrateurs.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[90%] h-full rounded-full"></div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <div class="card">
            <div class="face face1">
              <div class="content">
                <font-awesome-icon class="text-7xl" :icon="['fab', 'js']" />
                <h3>GSAP</h3>
              </div>
            </div>
            <div class="face face2">
              <div class="content">
                <p>GSAP est une bibliothèque pour animer des éléments de sites web avec une performance optimale et une compatibilité étendue à tous les navigateurs.</p>
                <div class="flex gap-2 items-center mt-6">
                    <span class="text-gray-700 text-sm">Niv</span>
                    <div class="w-full h-5 rounded-full border border-gray-300 lvl-box">
                        <div class="w-[90%] h-full rounded-full"></div>
                    </div>
                </div>  
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'MainSkills',
}
</script>
