<template lang="">
    <header class="h-12 container fixed top-8 left-1/2 -translate-x-2/4 rounded-full z-50 w-full sm:w-9/12">
      <div class="flex flex-row gap-4 relative h-full sm:px-4 justify-between items-center">
        <div class="flex items-center">
          <router-link to="/" title="Home">
            <img class="w-16 py-2 h-full" src="@/assets/images/CW-Blanc.svg" alt="LOGO">
          </router-link>
        </div>


        <div class="flex gap-y-4 gap-x-44 items-center">
          <div id="dropdown-menu" class="flex items-center justify-end py-2 gap-4">
            <div class="font-bold">Menu</div>
            <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="2.33337" y1="2" x2="9.00004" y2="2" stroke="white" stroke-width="4" stroke-linecap="round"/>
              <line x1="2.33337" y1="10" x2="21" y2="10" stroke="white" stroke-width="4" stroke-linecap="round"/>
              <line x1="13" y1="18" x2="21" y2="18" stroke="white" stroke-width="4" stroke-linecap="round"/>
            </svg>
          </div>
        </div>
      </div>
    </header>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    msg: String
  },
  mounted() {
    
  }
}
</script>

<style lang="">
    
</style>